img[data-imgCrop],
img[data-imgSquare] {
	object-fit: cover;
}

/*Seccions pàgines composades*/
section.composades {
	padding: 2.5rem 0;
}

/** Slide **/
.swiper {
	width: 100%;

	.swiper-slide img,
	.swiper-slide video {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.swiper-button-prev {
		color: $primary;
	}

	.swiper-button-next {
		color: $primary;
	}

	.swiper-pagination-bullet-active {
		background: #000;
	}

	a {
		color: inherit;
	}
}

/** effectes hover **/

.effect_hover {
	/*.shadow {
			transition: 0.2s all;
		}
		.shadow:hover {
			transform: scale(1.02);
		}*/

	.shadow {
		border-radius: $border-radius-sm;
	}

	.hover {
		display: block;
		text-decoration: none;
		position: relative;
		overflow: hidden;
		height: 100%;

		img {
			border-radius: $border-radius-sm;
		}
	}

	.hidden {
		background-color: rgba(0, 0, 0, 0.3);
		position: absolute;
		z-index: 10;
		transition: 0.3s all;
		overflow: hidden;
		width: 100%;
		height: 100%;
		opacity: 0;
		border-radius: $border-radius-sm;
	}

	.fade,
	.zoom {
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
	}

	.topBottom {
		height: 100%;
		top: -100%;
		left: 0;
	}

	.leftRight {
		height: 100%;
		left: -100%;
		width: 100%;
	}

	.rightLeft {
		height: 100%;
		right: -100%;
		width: 100%;
	}

	.bottomTop {
		height: 100%;
		bottom: -100%;
		left: 0;
	}

	.hidden .text {
		display: flex;
		flex-direction: column;
		text-align: left;
		justify-content: center;
		height: 100% !important;
		color: #fff;
		padding: 1.25rem;
	}

	.hidden .text h2 {
		width: 10ch;
	}

	.hidden .text h4 {
		color: #fff !important;
	}

	.hover:hover .hidden {
		opacity: 1;
	}

	.hover:hover .topBottom {
		top: 0;
	}

	.hover:hover .leftRight {
		left: 0;
	}

	.hover:hover .rightLeft {
		right: 0;
	}

	.hover:hover .bottomTop {
		bottom: 0;
	}

	.invertit .hidden {
		opacity: 1;
	}

	.invertit .topBottom {
		top: 0;
	}

	.invertit .leftRight {
		left: 0;
	}

	.invertit .rightLeft {
		right: 0;
	}

	.invertit .bottomTop {
		bottom: 0;
	}

	.invertit:hover .hidden {
		opacity: 1;
	}

	.invertit:hover .topBottom {
		top: 100%;
	}

	.invertit:hover .leftRight {
		left: 100%;
	}

	.invertit:hover .rightLeft {
		right: 100%;
	}

	.invertit:hover .bottomTop {
		bottom: 100%;
	}

	.invertit:hover .fade {
		opacity: 1;
		background: rgba(0, 0, 0, 0.1) !important;
	}

	.invertit:hover .zoom {
		opacity: 1;
		background: rgba(0, 0, 0, 0.1) !important;
		transform: scale(1.1);
	}

	h4 {
		font-size: 1.25rem;
		font-weight: bold;
		margin-bottom: 0.7rem;
	}

	p {
		font-size: 1rem;
		font-weight: normal;
	}
}

/** Destacats portada */
.destacats {
	.card {
		border-radius: $border-radius-sm;

		img {
			border-top-left-radius: $border-radius-sm;
			border-top-right-radius: $border-radius-sm;
		}
	}

	.icona {
		font-size: 6rem;
		text-align: center;
		padding: 2rem 0 0 0;
		color: $primary;
	}
}

/** Mostrar la data triangle en notícies portada **/
.data-triangle {
	color: #222;
	position: absolute;
	top: 0;
	left: 0;
	width: 9rem;
	height: 9rem;
	padding: 0.5rem;
	background: linear-gradient(135deg, $primary 50%, transparent 50%);

	span {
		display: block;
		font-weight: bold;
		color: #fff;
	}

	span.dia {
		font-size: 2.1rem;
		line-height: 2.3rem;
	}

	span.mes {
		font-size: 1.2rem;
		text-transform: uppercase;
		line-height: 1.3rem;
		font-weight: 500;
	}

	span.any {
		font-size: 1rem;
		font-weight: normal;
	}
}

.data_label {
	display: inline-block;
	background: $primary;
	font-size: 0.9rem;
	color: #222;
	position: relative;
	padding: 5px 20px;
	text-transform: uppercase;
	border-radius: 30px;
	margin-top: 10px;

	span {
		font-weight: bold;
		color: $dark;
	}
}

/** paginador **/
.page-item.active .page-link {
	background: $primary;
	border: 1px solid $primary;
}

.page-item.active {
	color: #fff !important;
}

.page-link {
	color: #424242;
}

.page-link:hover {
	color: $primary;
}

/** fletxa en moviment **/
#fletxa_moviment {
	position: absolute;
	width: 4rem;
	height: 4rem;
	left: 50%;
	margin-left: -2rem;
	text-align: center;
	z-index: 10;
	font-size: 2rem;
	color: #fff;
	display: none;
	animation: MoveUpDown 3s linear infinite;
	bottom: 8rem;

	svg {
		width: 90%;
	}
}

@keyframes MoveUpDown {
	0% {
		bottom: 3.75rem;
	}
	50% {
		bottom: 2.5rem;
	}
	100% {
		bottom: 3.75rem;
	}
}

/** fletxa tornar al principi **/
.back-to-top {
	position: fixed;
	overflow: hidden;
	bottom: 2em;
	right: 2em;
	text-decoration: none;
	color: #fff;
	width: 3rem;
	height: 3rem;
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 100%;
	text-align: center;
	cursor: pointer;
	display: none;
	z-index: 1000;

	i {
		display: block;
		line-height: 3rem;
		margin: 0 auto;
		font-size: 2.5rem;
		color: #000;
	}
}

/** owl carousel **/
.owl-carousel {
	button.owl-prev {
		position: absolute;
		top: 50%;
		transform: translateY(-25%);
		left: -40px;
		z-index: 10000;
		font-size: 40px;
	}

	button.owl-next {
		position: absolute;
		top: 50%;
		transform: translateY(-25%);
		right: -40px;
		z-index: 10000;
		font-size: 40px;
	}

	.owl-theme .owl-nav [class*="owl-"]:hover {
		background: none;
	}

	button i {
		font-size: 3.1rem;
		line-height: 3.1rem !important;
		margin: 0;
		padding: 0;
	}

	button svg {
		width: 4rem;
		padding: 0.4rem;
	}

	button.disabled i,
	button.disabled svg {
		color: lighten($primary, 30%);
	}

	.owl-stage-outer {
		display: flex;
	}

	.owl-stage {
		display: flex !important;
	}

	.owl-dots {
		display: flex;
		justify-content: center;
		margin: 0.5rem;
	}

	.owl-dot {
		display: inline-block;
		margin: 0.2rem;
	}

	.owl-dot span {
		display: block;
		width: 0.8rem;
		height: 0.8rem;
		background: grey;
		border-radius: 100%;
	}

	.owl-dot.active span {
		background: $primary;
	}
}

/** cercador **/
#cercar {
	padding: 0 1.8rem 0 0.2rem;
	background: $greylight;
	position: relative;
	border-radius: 1.8rem;
	width: 9rem;
	min-width: 12rem;

	input {
		background: $greylight;
		border-radius: 30px;
		width: 100%;
	}

	i {
		position: absolute;
		right: 0.8rem;
		top: 50%;
		height: 1rem;
		margin-top: -0.5rem;
	}

	input[type="search"] {
		height: 2rem;
		padding-right: 0;
		line-height: 2rem;
		border: none !important;
		font-size: 0.9rem;
	}

	input[type="search"]:focus,
	input[type="search"]:active,
	input[type="search"]:hover,
	input[type="search"]:active {
		outline: 0px !important;
		-webkit-appearance: none;
		box-shadow: none !important;
	}
}

/* Data en format "cube" */
.data-box {
	position: relative;
	overflow: hidden;
	clear: both;
	text-align: center;
	background: #eeeeee;
	padding: 10px 0;
	height: auto !important;

	span {
		position: relative;
		clear: bottom;
		text-align: center;
		display: block;
		color: #000;
	}

	span.dia {
		font-size: 2rem;
		font-weight: bold;
		line-height: 2.3rem;
	}

	span.mes {
		text-transform: uppercase;
		line-height: 1.3rem;
		font-size: 1.2rem;
	}

	span.any {
		font-size: 1rem;
	}
}

/* Per la lliure "parallax" */
.parallax-window {
	min-height: 25rem;
	background: transparent;
}

.parallax-text {
	padding: 2.5rem;
}

#nose2_full_width {
	.grid-item {
		margin-bottom: 0 !important;
	}

	.col-text {
		padding: 2rem;

		&.order-2 {
			padding: 4rem 0 1rem 3rem;
		}

		&.order-1 {
			padding: 4rem 3rem 5rem 0;
		}

		h2 {
			margin-top: 1rem;
			margin-bottom: 3.2rem;
		}
	}
}

/* Estil bàsic per la bona visualització de les lliures */
.lliures {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 1rem;
		font-weight: 600;
	}

	.bx-wrapper {
		margin-bottom: 1rem;
	}

	table {
		border: none;
	}

	ul {
		margin-top: 1.25rem;
	}

	.icon {
		width: 100%;
		position: relative;
	}

	.icon i {
		font-size: 10rem;
	}

	.grid-item {
		margin-bottom: 2.5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	img {
		margin-bottom: 1.25rem;
	}

	.fluid-width-video-wrapper {
		margin-bottom: 1.25rem;
	}
}

.spinner {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 101;
	background: #fff;

	.spinner-grow {
		margin: 0 0.2rem;
	}
}

.box_spinner {
	display: flex;
	height: calc(100vh - 12.5rem);
	align-items: center;
	justify-content: center;
}

.box_spinner .spinner-border {
	color: $primary;
	width: 4rem;
	height: 4rem;
}

.box_spinner .spinner-grow {
	color: $primary;
}

/** BUTTONS **/
.btn-rounded {
	border-radius: 1.8rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.btn-icon {
	padding-right: 2.8rem;
	text-align: left;
	transition: 0.15s background-position;
}

.icon-arrow-right {
	background-image: url("../img/icon-arrow-right.svg");
	background-position: calc(100% - 1rem) 50%;
	background-repeat: no-repeat;

	&:hover {
		background-position: calc(100% - 0.8rem) 50%;
	}
}

.icon-arrow-left {
	background-image: url("../img/icon-arrow-left.svg");
	background-position: calc(100% - 1rem) 50%;
	background-repeat: no-repeat;

	&:hover {
		background-position: calc(100% - 1.2rem) 50%;
	}
}

/** CARD **/
.card-bordered {
	border-radius: $border-radius-md;

	img {
		border-top-left-radius: $border-radius-md;
		border-top-right-radius: $border-radius-md;
		max-height: 258px;
		width: 100%;
		object-fit: cover;
	}

	.card-body {
		padding: 2.4rem;

		p {
			font-size: 0.9rem;
		}
	}
}
