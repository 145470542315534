// Colors
$primary: #31afc5;
$secondary: #2a57f7;
$greylight: #dcdcdc;
$black: #000000;
$login: $primary;

// Menú
$menuBg: #31afc5;
$menuColor: #202124;
$menuColorHover: #fff;
$menuColorSelected: #fff;
$menuColorSeparator: darken($menuBg, 20%);
$menuBgSubmenu: #fff;

// Components
$boxBg: #f8f9fa;
$boxColorSeparator: #dadce0;
$boxColor: #202124;

// Components
$border-radius-sm: 0.6rem;
$border-radius-md: 1.5rem;
