html {
	font-size: 16px;
	font-family: "Open Sans", serif;
	color: #424242;
}

html,
body {
	width: 100%;
	position: relative;
	margin: 0;
	padding: 0;
}

body {
	min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	font-family: "Roboto", sans-serif;

	a {
		color: inherit;
	}
}

ul,
p {
	margin: 0;
	padding: 0;
}

.wow {
	visibility: hidden;
}

a {
	color: $primary;
	text-decoration: none;
}

header {
	a:hover {
		text-decoration: none;
	}
}

main {
	overflow: hidden;
	clear: both;
	position: relative;
	padding-bottom: 2.5rem;
	min-height: 80vh;

	ul {
		margin: 0 0 0 2.5rem;
		padding: 0;
	}
}

.jumbotron {
	padding: 30px 0;
	border-radius: 0;
	border: none;
	margin: 0;
	color: $primary;

	h1 {
		color: currentColor;
		text-align: center;
		font-size: 2.5rem;
		margin: 0;
		font-weight: 400;
	}
}

footer {
	background: $primary;
	color: white;
	padding: 3rem 0;

	p {
		margin: 0;
		padding: 0;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	a:hover {
		text-decoration: underline;
	}

	a i {
		color: inherit;
	}

	h4 {
		margin-bottom: 1rem;
	}
}

.idiomes {
	list-style: none;

	li {
		float: left;
		display: inline;
		margin: 0 3px;
	}

	li a {
		color: #333;
	}

	li a.selected {
		color: #333;
		font-weight: bold;
	}
}

.linia {
	width: 40px; /* Anchura de la línea */
	height: 2px; /* Altura de la línea */
	background-color: #a5a5a5; /* Color de la línea */
	margin: 0 auto 1rem auto; /* Centrar horizontalmente */
}

#horaris {
	h3 {
		line-height: 66px;
		font-size: 56px;
	}
	h4 {
		font-size: 28px;
		line-height: 20px;
		font-weight: 700;
		color: #a5a5a5;
	}
	p {
		margin-bottom: 1.2rem;
		font-size: 26px;
		line-height: 20px;
		color: #a5a5a5;
	}
}

.titol-composades {
	display: none;
}

#productos {
	h4 {
		line-height: 40px;
		font-size: 30px;
		color: #31b0c5;
	}
	p {
		font-size: 20px;
		font-weight: 700;
	}
	.lliures .grid-item:last-child {
		margin-bottom: 2.5rem !important;
	}
}

.ch:hover {
	background-image: -webkit-linear-gradient(
		left,
		rgb(76, 157, 181),
		rgb(75, 172, 201) 50%,
		rgb(118, 196, 219) 100%
	);
	background-image: linear-gradient(
		to right,
		rgb(76, 157, 181),
		rgb(75, 172, 201) 50%,
		rgb(118, 196, 219) 100%
	);
	color: #fff;
}

#servicios {
	h4 {
		line-height: 40px;
		font-size: 30px;
		color: #31b0c5;
	}
	p {
		font-size: 16px;
		font-weight: 600;
	}
	.lliures .grid-item:last-child {
		margin-bottom: 2.5rem !important;
	}
}

#catsalut {
	p {
		font-size: 20px;
		line-height: 1.3;
		font-weight: 700;
	}
}

#historia-toraq {
	background-color: #f2f2f2;
	p {
		color: #414141;
	}
	h5 {
		line-height: 28px;
		font-size: 18px;
		font-weight: 600;
		color: #a5a5a5;
	}
	h3 {
		font-size: 36px;
	}
}
